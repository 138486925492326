import React from 'react';
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {CLIENT_ACTIVATION_CODE_MAX_LENGTH, CONFIG_API_HOSTS, DEFAULT_CONFIG_ID} from "../../config";
import {toast, ToastContainer} from "react-toastify";
import Api from "../../services/api/Api";
import {Auth} from "../../services/auth/Auth";
import {mdiCellphoneAndroid, mdiEmail, mdiNumeric, mdiPhone} from "@mdi/js";
import Icon from "@mdi/react";

import './LoginPage.css';

interface ILoginFormProps {
    onSuccess: () => void;
}

interface ILoginPageSettings {
    is_http_query_running: boolean;
    is_server_choice_visible: boolean;
    is_submit_button_enabled: boolean;
}

interface ILoginFormState {
    form: {
        username: string | null;
        activation_code: string | null;
        hostname: string;
    },
    settings: ILoginPageSettings;
}

class LoginPage extends React.Component<ILoginFormProps, ILoginFormState> {
    toastContainer: any;
    state = {
        form: {
            username: '',
            activation_code: null,
            hostname: CONFIG_API_HOSTS[DEFAULT_CONFIG_ID].hostname
        },
        settings: {
            is_http_query_running: false,
            is_server_choice_visible: false,
            is_submit_button_enabled: false
        }
    };

    constructor(props: any) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
        this.onFormInputChange = this.onFormInputChange.bind(this);
        this.toastContainer = React.createRef();
    }

    async requestActivationCode() {
        if (this.state.settings.is_http_query_running) {
            return  toast(`Try again`, {
                type: 'info',
                autoClose: 1500
            });
        }

        this.setSettings('is_http_query_running', true);

        const toastId = toast(`Requesting activation code...`, {
            type: 'info',
            autoClose: 3000
        });

        try {
            Auth.getInstance().setHostname(this.state.form.hostname);
            await Api.getInstance().clientSignUp(this.state.form.username);

            toast.dismiss(toastId);
            toast('Check you phone', {
                type: 'success',
                autoClose: 3000
            });
            this.setSettings('is_http_query_running', false);
        }
        catch (err) {
            setTimeout(() => {
                toast.dismiss(toastId);
                toast(err.message, {
                    type: 'error',
                    autoClose: 3000
                });
            }, 1500);
            this.setSettings('is_http_query_running', false);
        }
    }


    async login() {
        if (this.state.settings.is_http_query_running) {
            return  toast(`Try again`, {
                type: 'info',
                autoClose: 1500
            });
        }

        this.setSettings('is_http_query_running', true);

        const toastId = toast(`Logging in`, {
            type: 'info',
            autoClose: 3000
        });

        try {
            Auth.getInstance().setHostname(this.state.form.hostname);
            const response: {clientId: number} = await Api.getInstance().clientSignIn(
                this.state.form.username,
                String(this.state.form.activation_code)
            );


            toast.dismiss(toastId);
            toast(`Logged in`, {
                type: 'success',
                autoClose: 3000
            });

            console.log('auth response:', response);

            Auth.getInstance().setCredentials(this.state.form.username, String(this.state.form.activation_code))
            Auth.getInstance().setIsAuthenticated(true);
            this.props.onSuccess();
        }
        catch (err) {
            setTimeout(() => {
                toast.dismiss(toastId);
                toast(err.message, {
                    type: 'error',
                    autoClose: 3000
                });
                this.onFormInputChange('activation_code', '');
                Auth.getInstance().setIsAuthenticated(false);
                this.setSettings('is_http_query_running', false);
            }, 1500);
        }
    }

    async onSubmit(event: any) {
        if ((this.state.form.activation_code || '').trim().length > 0) {
            this.login();
        } else {
            this.requestActivationCode();
        }
    }

    setSettings(key: keyof ILoginPageSettings, value: any) {
        this.setState({
            settings: Object.assign(this.state.settings, {
                [key]: value
            })
        })
    }

    getSubmitButtonMessage(): string {
        return (this.state.form.activation_code || '').trim().length > 0 ? 'LOGIN' : 'GET PIN';
    }

    async onFormInputChange(key: 'username' | 'activation_code' | 'hostname', event: any) {
        const isSetAllowed: boolean = key === 'activation_code' ? !this.state.settings.is_http_query_running : true;

        if (isSetAllowed) {
            this.setState({
                form: Object.assign(this.state.form, {
                    [key]: (event.target ? event.target.value : event).replace(' ', '')
                })
            });
        }

        if (this.state.form.username.length > 0) {
            this.setSettings('is_submit_button_enabled', this.state.form.username.length > 5); // TODO: Const
        }
        /*if (key === 'activation_code' && (event.target ? event.target.value : event).length === CLIENT_ACTIVATION_CODE_MAX_LENGTH) {
            await this.login();
        }*/
    }

    render() {
        return (
            <>
                {this.state.settings.is_http_query_running}
                <ToastContainer ref={this.toastContainer}/>
                <img src={"/logo.svg"} alt={"Taxigo Express"} className={"mt-5 mb-5"}/>
                <br/>
                <Container className={"order-login-container"}>
                    <Row className="justify-content-sm-center">
                        <Col xl={"12"} sm={"12"} md={"12"}>
                            <h6 className={"login-form-heading-1 noselect"}>Send anything to anyone - Fast</h6>
                            <h3 className={"login-form-heading-2 noselect"}>Over 1000 cars at your disposal. Pay by Cash or Credit Card.</h3>
                            <div className={"login-form-wrapper p-5 text-left"}>
                                <h5 className={"mb-0 login-form-upper-title noselect"}>If it fits - we deliver!</h5>
                                <br/>
                                <Form>
                                    <Form.Group>
                                        <Form.Label className={"noselect"}><b>Enter phone</b></Form.Label>
                                        <div className={"login-page-input-wrapper"}>
                                            <Icon size={1} path={mdiCellphoneAndroid} className={"mr-2 input-icon"}/>
                                            <Form.Control
                                                type="text"
                                                value={this.state.form.username}
                                                maxLength={17}
                                                placeholder="+372 5100 5100"
                                                disabled={this.state.settings.is_http_query_running}
                                                onChange={(event: any) => this.onFormInputChange('username', event)}
                                                className={"login-form-text-input"}
                                            />
                                        </div>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label className={"noselect"}><b>Activation code</b></Form.Label>
                                        <div className={"login-page-input-wrapper"}>
                                            <Icon size={1} path={mdiNumeric} className={"mr-2 input-icon"}/>

                                            <Form.Control
                                                type="password"
                                                value={String(this.state.form.activation_code || '')}
                                                maxLength={CLIENT_ACTIVATION_CODE_MAX_LENGTH}
                                                placeholder="5100"
                                                disabled={this.state.settings.is_http_query_running}
                                                onChange={(event: any) => this.onFormInputChange('activation_code', event)}
                                                className={"login-form-text-input"}
                                            />
                                        </div>
                                    </Form.Group>
                                    {(this.state.settings.is_server_choice_visible) && (
                                        <Form.Group>
                                            <Form.Control
                                                as="select"
                                                size={"sm"}
                                                onChange={ (event :any) => this.onFormInputChange('hostname', event)}>
                                                {CONFIG_API_HOSTS.map((host: any, index) => {
                                                    return (<option value={host.hostname} key={index}>{host.title}</option>);
                                                })}
                                            </Form.Control>
                                        </Form.Group>
                                    )}

                                    <Button
                                        className={"login-form-button m-auto"}
                                        disabled={!this.state.settings.is_submit_button_enabled}
                                        onClick={(e: any) => {
                                            this.onSubmit(e);
                                            e.preventDefault();
                                        }}
                                    >{this.getSubmitButtonMessage()}</Button>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row className={"login-page-footer-contacts"}>
                        <Col xs={"6"} sm={"3"}>
                            <Icon size={1} path={mdiEmail} className={"mr-2 login-page-align-icon-upper"}/>
                            <a href={"mailto:hello@taxigo.ee"}>hello@taxigo.ee</a>
                        </Col>
                        <Col xs={"6"} sm={"3"}>
                            <Icon size={1} path={mdiPhone} className={"mr-2 login-page-align-icon-upper"}/>
                            <span><a href={"tel:+37258150000"}>+372 5815 0000</a></span>
                        </Col>
                        <Col xs={"6"} sm={"3"}>
                            <a href={"https://taxigo.com/express/express-terms-and-privacy-en/"} className={"underline"} target={"_blank"}  rel="noopener noreferrer">Terms & Privacy</a>
                        </Col>
                        <Col xs={"6"} sm={"3"}>Taxigo OÜ</Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default LoginPage;
