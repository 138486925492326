import {decorate} from "mobx";
import {IApiCredentials,} from "../interfaces";
import Api from "../services/api/Api";
import {Auth} from "../services/auth/Auth";
import {WebsocketNamespace} from "../config";
import {Websocket, WebsocketDefaultEvent, WebsocketEvent} from "../services/Websocket/Websocket";
import {OrderPageState} from "./OrderPage.observer";

declare var document: any;



export class AppState {
    isSocketRunning: boolean = false;
    orderPageStore = new OrderPageState();

    async initializeWebsocket(): Promise<void> {
        if (this.isSocketRunning) {
            alert('initializeWebsocket error: already running');
            return;
        }

        const credentials: IApiCredentials = Auth.getInstance().getCredentials();
        const namespace: WebsocketNamespace = Auth.getInstance().getWebsocketNamespace();
        const hostname: string = Auth.getInstance().getHostname();
        const ws: Websocket = Websocket.getInstance();

        ws.setHostname(hostname)
            .setNamespace(namespace)
            .setCredentials(credentials.username, credentials.password)
            .connect()
            .on(WebsocketDefaultEvent.Connect, (data: any) => {
                ws.on(WebsocketEvent.RefreshTasks, (data: {ispong: boolean; hash:string}) => {
                    console.log('RefreshTasks event received');
                    data.ispong && Api.getInstance().turnOffPong({action: WebsocketEvent.RefreshTasks, task_id: -1});
                    this.orderPageStore.refreshOrdersList();
                });
                ws.on(WebsocketEvent.RefreshTasksV2, (data: {ispong: boolean; hash:string, task_id: number}) => {
                    console.log('RefreshTasks event v2 received');
                    data.ispong && Api.getInstance().turnOffPong({action: WebsocketEvent.RefreshTasksV2, task_id: data.task_id});
                    this.orderPageStore.refreshOrdersList();
                });

                ws.on(WebsocketEvent.ClientBoarded, (data: {ispong: boolean; hash:string, task_id: number}) => {
                    console.log('ClientBoarded event received');
                    data.ispong && Api.getInstance().turnOffPong({action: WebsocketEvent.ClientBoarded, task_id: data.task_id});
                    this.orderPageStore.refreshOrdersList();
                });

                ws.on(WebsocketEvent.TaskTaken, (data: {ispong: boolean; hash:string, task_id: number}) => {
                    console.log('TaskTaken event received');
                    data.ispong && Api.getInstance().turnOffPong({action: WebsocketEvent.TaskTaken, task_id: data.task_id});
                    this.orderPageStore.refreshOrdersList();
                });
                ws.on(WebsocketEvent.DriverArrived, (data: {ispong: boolean; hash:string, task_id: number}) => {
                    console.log('DriverArrived event received');
                    data.ispong && Api.getInstance().turnOffPong({action: WebsocketEvent.DriverArrived, task_id: data.task_id});
                    this.orderPageStore.refreshOrdersList();
                });
                ws.on(WebsocketEvent.RequestTimeout, (data: {task_id: number, hash: number, source: string}) => {
                    console.log('RequestTimeout event received');
                    if (data.hash) {
                        Api.getInstance().turnOffPong({
                            action: WebsocketEvent.RequestTimeout,
                            task_id: data.task_id
                        });
                        // [{task_id: 18729086, dispatcher_id: 324, source: "web", hash: 410}
                    }
                    //Api.getInstance().turnOffPong({action: WebsocketEvent.RequestTimeout, task_id: data.task_id});
                });
                ws.on(WebsocketEvent.TaskCanceled, (data: {ispong: boolean; hash: string; task_id: number}) => {
                    console.log('TaskCanceled event received');
                    data.ispong && Api.getInstance().turnOffPong({action: WebsocketEvent.TaskCanceled, task_id: data.task_id});
                    this.orderPageStore.refreshOrdersList();
                });

                ws.on(WebsocketEvent.TaskCompleted, (data: {task_id: number; ispong: boolean; hash: number}) => {
                    console.log('TaskCompleted event received')
                    Api.getInstance().turnOffPong({action: WebsocketEvent.TaskCompleted, task_id: data.task_id});
                    this.orderPageStore.refreshOrdersList();
                });
            })
            .on(WebsocketEvent.DriverArrived, (data: any) => {
                this.orderPageStore.refreshOrdersList();
            })
            .on(WebsocketDefaultEvent.Disconnect, (data: any) => {
                console.log('socket disconnected');
            });

        return;
    }
}


decorate (AppState, {
});
