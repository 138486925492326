import axios from "axios";
import {Auth} from "../auth/Auth";
import {
    ICreateOrderInput, //IDQBClientAppBuildCategoriesResponse, IDQBClientAppNoCategoriesResponse,
    IDriversQueueRequest, IGetCabTaskExtra,
    IGetLocationSettings, IOrderCreateResponse
} from "../../interfaces";
import { FirebaseService } from "../firebase/firebase.service";
import { FIREBASE_CONFIG, RECAPTCHA_ENTERPRISE_KEY } from "../../config";
import { getToken } from "firebase/app-check";


export class Api {
    private static instance: Api;

    private firebase = new FirebaseService(FIREBASE_CONFIG, RECAPTCHA_ENTERPRISE_KEY)

    private constructor() {
    }

    static getInstance(): Api {
        if (!Api.instance) {
            Api.instance = new Api();
        }

        return Api.instance;
    }

    private handleServerErrorResponse(err) {
        if (err && err.response && err.response.data && err.response.data.message) {
            return new Error(err.response.data.message)
        }

        return  err;
    }

    hostname() {
        return Auth.getInstance().getHostname();
    }

    async clientSignUp(username: string, language = 'en'): Promise<void> {
        try {
            const token = (await getToken(this.firebase.appCheck)).token

            await axios.post(this.hostname() + '/v2/client/sign-up', {
                language: language, // TODO Later
                username: username,
            }, {
                withCredentials: true,
                headers: {
                    'X-Firebase-AppCheck': token
                }
            });
        }
        catch (err) {
           throw this.handleServerErrorResponse(err);
        }
    }

    async clientSignIn(username: string, activationCode: string, language = 'en'): Promise<{ clientId: number }> {
        try {
            const response = await axios.post(this.hostname() + '/v2/client/sign-in', {
                language: language,
                username: username,
                activation_code: activationCode
            }, {
                withCredentials: true
            });

            return {
                clientId: response.data.id
            };
        }
        catch (err) {
            throw this.handleServerErrorResponse(err);
        }
    }

    async isUserAuthorized(as: string | null = null, activationCode: string): Promise<{ id: number }> {
        const query: string = as ? `?as=${as}&activation_code=${activationCode}` : '';

        try {
            const response = await axios.get(this.hostname() + '/v2/user/me/is-authorized' + query, {
                withCredentials: true
            });

            return {
                id: response.data.id
            }
        }
        catch (err) {
            throw this.handleServerErrorResponse(err);
        }
    }

    async createOrder(input: ICreateOrderInput): Promise<IOrderCreateResponse> {
        try {
            const response = await axios.post(this.hostname() + `/v3/tasks`, input, {
                withCredentials: true
            });

            return response.data;
        }
        catch (err) {
            throw this.handleServerErrorResponse(err);
        }
    }

    async buildDriversQueue(input: IDriversQueueRequest): Promise<any/*IDQBClientAppBuildCategoriesResponse | IDQBClientAppNoCategoriesResponse*/> {
        const query: string = Object.keys(input).map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(input[key])).join('&');

        try {
            const response = await axios.get(this.hostname() + `/v2/drivers/search?${query}`, {
                withCredentials: true
            });

            return response.data;
        }
        catch (err) {
            throw this.handleServerErrorResponse(err);
        }
    }

    async getOrdersByUserId(id: string | number = 'me', options: {
        include_cancelled_tasks: boolean;
        include_cancelled_tasks_timeout_ms: number;
    } | null = null): Promise<any[]> { // TODO: Interface

        let query = '';

        if (options && options.include_cancelled_tasks) {
            query = '?include_cancelled_tasks=true&include_cancelled_tasks_timeout_ms=' + (options.include_cancelled_tasks_timeout_ms || 36e5) // 1h in ms
        }


        try {
            const response = await axios.get(this.hostname() + `/v3/clients/me/tasks/active` + query, {
                withCredentials: true
            });

            return response.data;
        }
        catch (err) {
            throw this.handleServerErrorResponse(err);
        }
    }

    async cancelOrderAsClient(orderId: number) {
        try {
            const response = await axios.post(this.hostname() + `/socket/client/cancel_task?apiVersion=1.7.0`, {
                task_id: orderId
            }, {
                withCredentials: true
            });

            return response.data;
        }
        catch (err) {
            throw this.handleServerErrorResponse(err);
        }
    }

    async getLocationsSettings(lat: number, lng: number): Promise<IGetLocationSettings> {
        try {
            const response = await axios.get(this.hostname() + `/v2/location-settings?latitude=${lat}&longitude=${lng}`, {
                withCredentials: true
            });

            return response.data;
        }
        catch (err) {
            throw this.handleServerErrorResponse(err);
        }
    }

    async turnOffPong(input: any) {
        try {
            const response = await axios.post(this.hostname() + '/socket/client/pong_fn?apiVersion=1.7.0', input, {
                withCredentials: true
            });

            return response.data;
        }
        catch (err) {
            throw this.handleServerErrorResponse(err);
        }
    }

    async clientLogout() {
        try {
            const response = await axios.get(this.hostname() + `/v2/client/logout`, {
                withCredentials: true
            });

            return response.data;
        }
        catch (err) {
            throw this.handleServerErrorResponse(err);
        }
    }

    async getDeliveryAccountDefaults(clientId: number | string = 'me') {
        try {
            const response = await axios.get(this.hostname() + `/v2/clients/${clientId}/delivery/defaults`, {
                withCredentials: true
            });

            return response.data;
        }
        catch (err) {
            throw this.handleServerErrorResponse(err);
        }
    }

    async getClientTrips(dateStart: string, dateEnd: string) {
        try {
            const response = await axios.get(this.hostname() + `/v2/client/trips?date_start=${dateStart}&date_end=${dateEnd}`, {
                withCredentials: true
            });

            return response.data;
        }
        catch (err) {
            throw this.handleServerErrorResponse(err);
        }
        // /v2/client/trips
    }


    async getCabTaskExtra(taskId: number): Promise<IGetCabTaskExtra> {
        try {
            const response = await axios.get(this.hostname() + `/v3/tasks/${taskId}/extra`, {
                withCredentials: true
            });

            return response.data;
        }
        catch (err) {
            throw this.handleServerErrorResponse(err);
        }
    }
}

export default Api;


