import {decorate, observable, action} from "mobx";
import {
    IClientOrder, IDeliveryAccountDefaults,
    IDqbCategoryTariff, IDQBClientAppBuildCategoriesResponse,
    IGetLocationSettings,
    IOrderFormData,
    IPaymentMethod
} from "../interfaces";
import Api from "../services/api/Api";
//import {Auth} from "../services/auth/Auth";
import {
    DELIVERY_CATEGORY_ID,
    DRIVER_REQUEST_METHOD, IS_PRODUCTION,
    PaymentMethod,
    SESSION_USER_SELF_IDENTIFICATOR,
    UNKNOWN_PAYMENT_METHOD_ID
} from "../config";
import {toast} from "react-toastify";
//import {Websocket, WebsocketDefaultEvent, WebsocketEvent} from "../services/Websocket/Websocket";

declare var document: any;



export class OrderPageState {
    orders: IClientOrder[] = [];
    orderForm: IOrderFormData = {
        pickup_address: '',
        pickup_address_lat: null,
        pickup_address_lng: null,
        pickup_contact_phone: '',
        pickup_contact_person: '',
        pickup_notes_for_courier:  '',
        pickup_describe_package: '',
        finish_address: '',
        finish_address_lat: null,
        finish_address_lng: null,
        finish_contact_phone: '',
        finish_contact_person: '',
        finish_notes_for_courier: '',
        payment_method_id: PaymentMethod.CASH,
        payment_method_client_company_id: UNKNOWN_PAYMENT_METHOD_ID,
        fixprice_hash: null
    };
    orderFormDefaults: IDeliveryAccountDefaults[] = [];
    orderTariff: IDqbCategoryTariff | null = null;
    paymentMethods: IPaymentMethod[] = [];
    isSocketRunning: boolean = false;

    private static instance: OrderPageState;

    constructor() {
        if (!OrderPageState.instance) {
            OrderPageState.instance = this;
        }

        return OrderPageState.instance;
    }


    async refreshOrdersList() {
        const response: IClientOrder[] = await Api.getInstance().getOrdersByUserId(SESSION_USER_SELF_IDENTIFICATOR, {
            include_cancelled_tasks: true,
            include_cancelled_tasks_timeout_ms: 36e5 // 1h
        });

        this.orders = response;
    }

    async getPaymentMethods(): Promise<void> {
        const response: IGetLocationSettings = await Api.getInstance().getLocationsSettings(59.4370, 24.7536);
        this.paymentMethods = response.payment_methods;
    }

    async getOrderFormDefaults() {
        const response: IDeliveryAccountDefaults[] = await Api.getInstance().getDeliveryAccountDefaults()
        this.orderFormDefaults = response;

        if (this.orderFormDefaults.length > 0) {
            const values: IDeliveryAccountDefaults = this.orderFormDefaults[0];

            this.setOrderForm({
                pickup_address_lat: values.pickup_address_lat || this.orderForm.pickup_address_lat,
                pickup_address_lng: values.pickup_address_lng || this.orderForm.pickup_address_lng,
                pickup_address: values.pickup_address || this.orderForm.pickup_address,
                pickup_contact_phone: values.pickup_phone || this.orderForm.pickup_contact_phone,
                pickup_contact_person: values.pickup_person || this.orderForm.pickup_contact_person,
                pickup_notes_for_courier: values.pickup_notes_for_courier || this.orderForm.pickup_notes_for_courier
            });
        }
    }

    setOrderForm(input: {[key: string /*keyof IOrderFormData*/]: string | number | null}): void {
        this.orderForm = Object.assign(this.orderForm, input);
    }

    setOrderTariff(input: IDqbCategoryTariff | null): void {
        this.orderTariff = input;
        return;
    }

    async clearForm() {
        this.orderForm = Object.assign(this.orderForm,{
            pickup_describe_package: '',
            finish_address: '',
            finish_address_lat: null,
            finish_address_lng: null,
            finish_contact_phone: '',
            finish_contact_person: '',
            finish_notes_for_courier: ''
        });
    }

    assembleExtraMessages(form: IOrderFormData): any[] {
        return [
            'Info available',
            form.pickup_describe_package,
            form.finish_notes_for_courier,
            form.finish_contact_person,
            form.finish_contact_phone
        ].map((item: string, index: number) => {
            return {
                message: item || null,
                priority: index + 1,
                instant: index === 0,
                display_when_state_id: 3,//TRIP.State.ONBOARD
                timeout: (index + 1) * 1000
            };
        })
        .filter((item) => item.message);
    }

    isContractPaymentMethodChosen(form: IOrderFormData): boolean {
        return !(form.payment_method_id in PaymentMethod);
    }

    async requestDriverSearch(form: IOrderFormData): Promise<{fixprice_hash: string} | void> {
        const searchPaymentMethod: string = this.paymentMethods.filter((method: IPaymentMethod) => {
            return method.type_id === (this.isContractPaymentMethodChosen(form) ? PaymentMethod.CONTRACT : form.payment_method_id);
        })[0].filter.payment_method;

        let driverSearchConditions = {
            start_latitude: Number(form.pickup_address_lat),
            start_longitude: Number(form.pickup_address_lng),
            payment_method: searchPaymentMethod,
            sorting: 'optimal',
            calculate_cost: 'true',
            finish_latitude: Number(form.finish_address_lat),
            finish_longitude: Number(form.finish_address_lng),
            qversion: '2019.2',
            display_disabled_categories: true
        };

        if (driverSearchConditions.payment_method === 'contract') {
            driverSearchConditions['contract_company_id'] = form.payment_method_client_company_id;
        }

        const gettingPriceToastId = toast('Getting price...', {
            type: 'default',
            autoClose: 3000
        });

        const queue: IDQBClientAppBuildCategoriesResponse = await Api.getInstance().buildDriversQueue(driverSearchConditions);

        const deliveryCategory =  queue!.categories.find((record) => {
            return record.category.id === DELIVERY_CATEGORY_ID;
        });

        if (!deliveryCategory) {
            toast('No delivery category found on your location', {
                type: 'error',
                autoClose: 3000
            });

            return;
        }

        const tariff: IDqbCategoryTariff = deliveryCategory.tariff;

        if (!tariff) {
            toast('Delivery category tariff not found', {
                type: 'error',
                autoClose: 3000
            });

            return;
        }

        this.setOrderTariff(tariff);

        toast.dismiss(gettingPriceToastId);

        return {
            fixprice_hash: queue.fixprice_hash
        };
    }

    async sendOrder(form: IOrderFormData) {
        let fixPriceHash = form.fixprice_hash;

        if (!form.fixprice_hash) {
          const response = await this.requestDriverSearch(form);

          if (response) {
              fixPriceHash = response.fixprice_hash;
          }
        }

        let taskCreateConditions = {
            task: {
                queue_id: null,
                fixprice_hash: fixPriceHash,
                comment: 'Delivery' + (form.pickup_notes_for_courier ? ` / ${form.pickup_notes_for_courier}` : '')  + (form.pickup_describe_package ? ` / ${form.pickup_describe_package} ` : '')
            },
            search: {
                method: DRIVER_REQUEST_METHOD.AUTOSEARCH,
                instant: true,
                app_category_id: DELIVERY_CATEGORY_ID
            },
            client: {
                app_platform_version: '13.0', // TODO
                app_platform: 'Android', // TODO
                app_version: '3.0.11' // TODO
            },
            start_location: {
                lat: form.pickup_address_lat,
                lng: form.pickup_address_lng,
                address: form.pickup_address,
            },
            extra_messages: this.assembleExtraMessages(form),
            extra: {
                pickup_notes_for_courier: form.pickup_notes_for_courier,
                finish_notes_for_courier: form.finish_notes_for_courier,
                finish_contact_person: form.finish_contact_person,
                finish_contact_phone: form.finish_contact_phone,
                package_description: form.pickup_describe_package
            },
            conditions: {
                payment_method: [form.payment_method_id],
                taxi_company: IS_PRODUCTION ? {
                    include: [],
                    exclude: []
                } : {
                    include: [356] // Coba's Taxi
                }
            },
            finish_location: {
                lat: form.finish_address_lat,
                lng: form.finish_address_lng,
                address: form.finish_address
            }
        };

        if (this.isContractPaymentMethodChosen(form)) {
            taskCreateConditions['conditions']['payment_method'] = [PaymentMethod.CONTRACT];
            taskCreateConditions['client']['company_id'] = form.payment_method_client_company_id;
        } else {
            taskCreateConditions['conditions']['payment_method'] = [form.payment_method_id];
        }

        return Api.getInstance().createOrder(taskCreateConditions);
    }

    /*async initializeWebsocket(): Promise<void> {
        if (this.isSocketRunning) {
            alert('initializeWebsocket error: already running');
            return;
        }

        const credentials: IApiCredentials = Auth.getInstance().getCredentials();
        const namespace: WebsocketNamespace = Auth.getInstance().getWebsocketNamespace();
        const hostname: string = Auth.getInstance().getHostname();
        const ws: Websocket = Websocket.getInstance();

        ws.setHostname(hostname)
            .setNamespace(namespace)
            .setCredentials(credentials.username, credentials.password)
            .connect()
            .on(WebsocketDefaultEvent.Connect, (data: any) => {
                ws.on(WebsocketEvent.RefreshTasks, (data: {ispong: boolean; hash:string}) => {
                    console.log('RefreshTasks event received');
                    data.ispong && Api.getInstance().turnOffPong({action: WebsocketEvent.RefreshTasks, task_id: -1});
                    this.refreshOrdersList();
                });
                ws.on(WebsocketEvent.RefreshTasksV2, (data: {ispong: boolean; hash:string, task_id: number}) => {
                    console.log('RefreshTasks event v2 received');
                    data.ispong && Api.getInstance().turnOffPong({action: WebsocketEvent.RefreshTasksV2, task_id: data.task_id});
                    this.refreshOrdersList();
                });

                ws.on(WebsocketEvent.ClientBoarded, (data: {ispong: boolean; hash:string, task_id: number}) => {
                    console.log('ClientBoarded event received');
                    data.ispong && Api.getInstance().turnOffPong({action: WebsocketEvent.ClientBoarded, task_id: data.task_id});
                    this.refreshOrdersList();
                });

                ws.on(WebsocketEvent.TaskTaken, (data: {ispong: boolean; hash:string, task_id: number}) => {
                    console.log('TaskTaken event received');
                    data.ispong && Api.getInstance().turnOffPong({action: WebsocketEvent.TaskTaken, task_id: data.task_id});
                    this.refreshOrdersList();
                });
                ws.on(WebsocketEvent.DriverArrived, (data: {ispong: boolean; hash:string, task_id: number}) => {
                    console.log('DriverArrived event received');
                    data.ispong && Api.getInstance().turnOffPong({action: WebsocketEvent.DriverArrived, task_id: data.task_id});
                    this.refreshOrdersList();
                });
                ws.on(WebsocketEvent.RequestTimeout, (data: {task_id: number, hash: number, source: string}) => {
                    console.log('RequestTimeout event received');
                    if (data.hash) {
                        Api.getInstance().turnOffPong({
                            action: WebsocketEvent.RequestTimeout,
                            task_id: data.task_id
                        });
                        // [{task_id: 18729086, dispatcher_id: 324, source: "web", hash: 410}
                    }
                    //Api.getInstance().turnOffPong({action: WebsocketEvent.RequestTimeout, task_id: data.task_id});
                });
                ws.on(WebsocketEvent.TaskCanceled, (data: {ispong: boolean; hash: string; task_id: number}) => {
                    console.log('TaskCanceled event received');
                    data.ispong && Api.getInstance().turnOffPong({action: WebsocketEvent.TaskCanceled, task_id: data.task_id});
                    this.refreshOrdersList();
                });

                ws.on(WebsocketEvent.TaskCompleted, (data: {task_id: number; ispong: boolean; hash: number}) => {
                    console.log('TaskCompleted event received')
                    Api.getInstance().turnOffPong({action: WebsocketEvent.TaskCompleted, task_id: data.task_id});
                    this.refreshOrdersList();
                });
            })
            .on(WebsocketEvent.DriverArrived, (data: any) => {
                this.refreshOrdersList();
            })
            .on(WebsocketDefaultEvent.Disconnect, (data: any) => {
                console.log('socket disconnected');
            });


        return;
    }*/
}


decorate (OrderPageState, {
    orders: observable,
    orderForm: observable,
    orderTariff: observable,
    orderFormDefaults: observable,
    clearForm: action,
    setOrderForm: action,
    setOrderTariff: action,
    refreshOrdersList: action,
    getOrderFormDefaults: observable
});
