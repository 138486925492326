import React from 'react';
import {Badge} from "react-bootstrap";
import {Auth} from "../../services/auth/Auth";

export default class Footer extends React.Component<any, any> {

    render() {
        const hostname: string = Auth.getInstance().getHostname()
            .replace('https://', '')
            .replace('http://', '')
            .replace('/api', '');


        return (
            <>
                <footer className={"text-center noselect mt-5"}>
                    <small>&copy; Copyright 2020, TAXIGO OÜ</small>
                    <br/>

                    { Auth.getInstance().isAuthenticated() && (
                        <h6>
                            <Badge variant={"success"} className={"h6"}>{hostname}</Badge>
                        </h6>
                    )}
                </footer>
            </>
        );
    }
}
