import { initializeApp, FirebaseApp } from 'firebase/app'
import { getAnalytics, Analytics } from "firebase/analytics";
import { initializeAppCheck, ReCaptchaEnterpriseProvider, AppCheck } from "firebase/app-check"
import { IFirebaseConfig } from '../../interfaces';

export class FirebaseService {

    private readonly firebaseConfig: IFirebaseConfig

    private readonly recaptchaEnterpriseKey: string

    readonly app: FirebaseApp

    readonly analytics: Analytics

    readonly appCheck: AppCheck

    constructor(config: IFirebaseConfig, recaptchaKey: string ) {
        this.firebaseConfig = config
        this.recaptchaEnterpriseKey = recaptchaKey

        this.app = initializeApp(this.firebaseConfig)
        this.analytics = getAnalytics(this.app)
        this.appCheck = initializeAppCheck(this.app, {
            provider: new ReCaptchaEnterpriseProvider(this.recaptchaEnterpriseKey),
            isTokenAutoRefreshEnabled: true
        })
    }

}
