import {observer} from "mobx-react";
import {OrderPageState} from "../../observers/OrderPage.observer";
import React, { Component }  from 'react';
import {Card, Col, Form, Row} from "react-bootstrap";
import {IDqbCategoryTariff, IOrderFormData, IPaymentMethod} from "../../interfaces";
import {
    GOOGLE_PLACES_LOOK_SUCH_COUNTRIES,
    PaymentMethod,
    UNKNOWN_PAYMENT_METHOD_ID
} from "../../config";
import {toast, ToastContainer} from "react-toastify";
import GooglePlacesAutocomplete, {geocodeByPlaceId} from 'react-google-places-autocomplete';
import 'react-google-places-autocomplete/dist/index.min.css';
import './OrderForm.css';
import {IOrderFormSettings, IOrderFormState} from "./interfaces";


declare var document: any;

export const OrderForm = observer (
    class OrdersList extends Component<{ store: OrderPageState }, IOrderFormState> {
        toastContainer: any;
        state: IOrderFormState = {
            settings: {
                is_http_query_running: false,
                is_mouse_hovered_on_submit: false
            }
        };

        constructor(props: {
            store: OrderPageState
        }) {
            super(props);
            this.toastContainer = React.createRef();
        }

        async requestFixPriceIfNeeded(form: IOrderFormData): Promise<void> {
            if (!this.isValidRoute() || !this.isValidAddresses()) {
                return;
            }

            this.setSettings('is_http_query_running', true);

            try {
                const response: { fixprice_hash: string } | void = await this.props.store.requestDriverSearch(form);

                if (response) {
                    this.props.store.setOrderForm({
                        fixprice_hash: response.fixprice_hash
                    });
                }
            }
            catch (err) {
                console.error(err);
            }

            this.setSettings('is_http_query_running', false);

            return;
        }

        componentDidMount(): void {
            this.setDefaultMethodAsContractIfItExists();
        }

        setDefaultMethodAsContractIfItExists() {
            const paymentMethod: IPaymentMethod | undefined = this.props!.store.paymentMethods.find((method: IPaymentMethod) => {
                return method.type_id === PaymentMethod.CONTRACT
            });

            if (paymentMethod) {
                this.props!.store.setOrderForm({
                    payment_method_id: Number(paymentMethod.filter.contract_company_id),
                    payment_method_client_company_id: Number(paymentMethod.filter.contract_company_id)
                })
            }
        }

        async submitOrder(form: IOrderFormData): Promise<void> {
            this.setSettings('is_http_query_running', true);

            try {
                await this.props.store.sendOrder(form);

                this.props.store.clearForm();
                this.props!.store.setOrderTariff(null);
                this.props.store.refreshOrdersList();
            }
            catch (err) {
                toast(err.message, {
                    type: 'error',
                    autoClose: 3000
                });
            }

            this.setSettings('is_http_query_running', false);
        }

        setSettings(key:  keyof IOrderFormSettings, value: any) {
            this.setState({
                settings: Object.assign(this.state.settings, {
                    [key]: value
                })
            })
        }

        async onAddressSelected(
            place,
            callback: (address: string, lat: number, lng: number) => void,
            errorCallback?: (err: any) => void
        ): Promise<void> {
            //const log: string = `onAddressSelected(place: ..., callback: ..., errorCallback: ...)`;

            try {
                const response = await geocodeByPlaceId(place.place_id);

                // @ts-ignore buggy types in library
                callback(place.description, response[0].geometry.location.lat(), response[0].geometry.location.lng());
            }
            catch (err) {
                errorCallback && errorCallback(err);
            }

            return;
        }

        assembleExtraMessages(): any[] {
            const form: IOrderFormData = this.props.store.orderForm;

            return [
                'Info available',
                form.pickup_describe_package,
                form.finish_notes_for_courier,
                form.finish_contact_person,
                form.finish_contact_phone
            ].map((item: string, index: number) => {
                return {
                    message: item || null,
                    priority: index + 1,
                    instant: index === 0,
                    display_when_state_id: 3,//TRIP.State.ONBOARD
                    timeout: (index + 1) * 1000
                };
            })
                .filter((item) => item.message);
        }

        isValidRoute(): boolean {
            const form: IOrderFormData = this.props.store.orderForm;

            return Boolean(form.pickup_address_lat)
                && Boolean(form.pickup_address_lng)
                && Boolean(form.finish_address_lat)
                && Boolean(form.finish_address_lng);
        }

        isValidAddresses(): boolean {
            // Why this way: GooglePlacesAutocomplete has no onChange event, and I can't handle event then user clearing input
            return document.getElementById('pickup-react-google-places-autocomplete-input').value.trim().length > 4
                && document.getElementById('finish-react-google-places-autocomplete-input').value.trim().length > 4;
        }

        getFixedPrice() {
            const tariff: IDqbCategoryTariff | null = this.props!.store.orderTariff;

            return tariff ? `${tariff.fixed_price} ${tariff.currency.sign}` : '';
        }

        isSubmitAllowed(): boolean {
            const form: IOrderFormData = this.props.store.orderForm;

            return this.isValidRoute()
                && this.isValidAddresses()
                && (form.pickup_contact_phone || '').length > 0
                && (form.finish_contact_phone || '').length > 0;
        }

        render() {
            const form: IOrderFormData = this.props.store.orderForm;

            // {componentRestrictions: {country: [GOOGLE_PLACES_LOOK_SUCH_COUNTRIES}}

            return (
                <Card className={"orderFormWrapper"}>
                    <ToastContainer ref={this.toastContainer}/>
                    <Row className="justify-content-sm-center text-left orderForm">
                        <Col sm={"12"} md={"6"} lg={"5"} xl={"6"}  className={"p-5"}>
                            <h3>NEW DELIVERY</h3>
                            <Form.Group>
                                <Form.Label>
                                    <small><b>Pickup address</b></small>
                                    <span className={"required-asterisk"}>*</span>
                                </Form.Label>
                                <GooglePlacesAutocomplete
                                    // @ts-ignore buggy library 2.1.0
                                    autocompletionRequest={{componentRestrictions: {country: GOOGLE_PLACES_LOOK_SUCH_COUNTRIES}}}
                                    idPrefix={"pickup"}
                                    initialValue={form.pickup_address}
                                    onSelect={(x) => {
                                        this.onAddressSelected(x, (address: string, lat: number, lng: number) => {
                                            this.props.store.setOrderForm({
                                                pickup_address: address,
                                                pickup_address_lat: lat,
                                                pickup_address_lng: lng,
                                            });
                                            this.requestFixPriceIfNeeded(this.props.store.orderForm);
                                        });
                                    }}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    <small><b>Contact number</b></small>
                                    <span className={"required-asterisk"}>*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    size={"lg"}
                                    value={form.pickup_contact_phone}
                                    disabled={this.state.settings.is_http_query_running}
                                    onChange={(e: any) => this.props.store.setOrderForm({pickup_contact_phone: e.target.value})}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    <small><b>Contact person</b></small>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    size={"lg"}
                                    value={form.pickup_contact_person}
                                    disabled={this.state.settings.is_http_query_running}
                                    placeholder={"(optional)"}
                                    onChange={(e: any) => this.props.store.setOrderForm({pickup_contact_person: e.target.value})}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    <small><b>Notes for courier</b></small>
                                </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    type="text"
                                    rows="1"
                                    size={"lg"}
                                    value={form.pickup_notes_for_courier}
                                    disabled={this.state.settings.is_http_query_running}
                                    placeholder={"Apartment, floor, room, outdoor guidance"}
                                    onChange={(e: any) => this.props.store.setOrderForm({pickup_notes_for_courier: e.target.value})}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    <small><b>Describe package</b></small>
                                </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    type="text"
                                    rows="1"
                                    size={"lg"}
                                    value={form.pickup_describe_package}
                                    disabled={this.state.settings.is_http_query_running}
                                    placeholder={"Book with yellow covers"}
                                    onChange={(e: any) => this.props.store.setOrderForm({pickup_describe_package: e.target.value})}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={"12"} md={"6"} lg={"5"} xl={"6"}  className={"p-5 deliveryDataBlock"}>
                            <h3>&nbsp;</h3>
                            <Form.Group>
                                <Form.Label>
                                    <small><b>Delivery address</b></small>
                                    <span className={"required-asterisk"}>*</span>
                                </Form.Label>
                                <GooglePlacesAutocomplete
                                    // @ts-ignore buggy library 2.1.0
                                    autocompletionRequest={{componentRestrictions: {country: GOOGLE_PLACES_LOOK_SUCH_COUNTRIES}}}
                                    initialValue={form.finish_address}
                                    idPrefix={"finish"}
                                    onSelect={(x) => {
                                        this.onAddressSelected(x, (address: string, lat: number, lng: number) => {
                                            this.props.store.setOrderForm({
                                                finish_address: address,
                                                finish_address_lat: lat,
                                                finish_address_lng: lng,
                                            });
                                            this.requestFixPriceIfNeeded(this.props.store.orderForm);
                                        });
                                    }}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    <small><b>Contact number</b></small>
                                    <span className={"required-asterisk"}>*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    size={"lg"}
                                    value={form.finish_contact_phone}
                                    disabled={this.state.settings.is_http_query_running}
                                    onChange={(e: any) => this.props.store.setOrderForm({finish_contact_phone: e.target.value})}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    <small><b>Contact person</b></small>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    size={"lg"}
                                    value={form.finish_contact_person}
                                    disabled={this.state.settings.is_http_query_running}
                                    placeholder={"(optional)"}
                                    onChange={(e: any) => this.props.store.setOrderForm({finish_contact_person: e.target.value})}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    <small><b>Notes for courier</b></small>
                                </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    type="text"
                                    rows="1"
                                    size={"lg"}
                                    value={form.finish_notes_for_courier}
                                    disabled={this.state.settings.is_http_query_running}
                                    placeholder={"Apartment, floor, room, outdoor guidance"}
                                    onChange={(e: any) => this.props.store.setOrderForm({finish_notes_for_courier: e.target.value})}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    <small><b>Who will pay</b></small>
                                </Form.Label>
                                <Form.Control
                                    as="select"
                                    size="lg"
                                    value={form.payment_method_id}
                                    onChange={(event: any) => {
                                        const methodId: number = Number(event.target.value);
                                        const isContractUsed: boolean = !(methodId in PaymentMethod);

                                        this.props.store.setOrderForm({
                                            payment_method_id: methodId,
                                            payment_method_client_company_id:  isContractUsed ? methodId : UNKNOWN_PAYMENT_METHOD_ID
                                        });

                                        this.requestFixPriceIfNeeded(this.props.store.orderForm);
                                    }}
                                >
                                    {this.props.store.paymentMethods.map((order: IPaymentMethod, index: number) => {
                                        const value = order.type_id === PaymentMethod.CONTRACT ? order.filter.contract_company_id : order.type_id;
                                        return (
                                            <option key={index} value={value}>{order.title.replace('In-app Everypay', 'Credit card')}</option>
                                        )
                                    })}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className={"text-center"}>
                        <Col sm={"12"} className={"p-5"}>
                            <input
                                type={"button"}
                                value={"GET COURIER " + this.getFixedPrice()}
                                className={"submitOrderFormButton"}
                                disabled={this.state.settings.is_http_query_running || !this.isSubmitAllowed()}
                                onMouseOver={(e: any) => {
                                    this.setSettings('is_mouse_hovered_on_submit', true);
                                }}
                                onMouseOut={(e: any) => {
                                    this.setSettings('is_mouse_hovered_on_submit', false);
                                }}
                                onClick={(event: any) => {
                                    this.submitOrder(this.props.store.orderForm);
                                    event.preventDefault();
                                }}/>
                            <br/>
                            <br/>
                        </Col>
                    </Row>
                </Card>
            );
        }
    }



)
