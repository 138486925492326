import React from 'react';
import './App.css';
import {Auth} from "./services/auth/Auth";
import LoginPage from "./modules/login/LoginPage";
import OrderPage from "./modules/order/OrderPage";
import {AclUserRole, CONFIG_API_HOSTS, DEFAULT_CONFIG_ID} from "./config";
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {IApiCredentials} from "./interfaces";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import OrderHistory from "./modules/order/OrderHistory";
import {Container} from "react-bootstrap";
import OrderPageHeader from "./modules/order/OrderPageHeader";
import Footer from "./modules/footer/Footer";
import {AppState} from "./observers/App.observer";


interface IAppProps {
}

interface IAppState {
    settings: {
        is_authorization_check_running: boolean;
    };
}

class App extends React.Component<IAppProps, IAppState> {
    store = new AppState();
    state: IAppState = {
        settings: {
            is_authorization_check_running: true
        }
    };

     setSettings(key: 'is_authorization_check_running', value: any) {
        this.setState({
            settings: Object.assign(this.state.settings, {
                [key]: value
            })
        })
    }

    async componentDidMount() {
        try {

            if (!Auth.getInstance().getHostname()) {
                Auth.getInstance().setHostname(CONFIG_API_HOSTS[DEFAULT_CONFIG_ID].hostname); // TODO: Fix this
            }

            const credentials: IApiCredentials = Auth.getInstance().getCredentials();
            const activationCode: string = String(credentials ? credentials.password : null);

            await Auth.getInstance().checkIsAuthorized(AclUserRole.Client, activationCode);
            await this.store.initializeWebsocket();
        }
        catch (err) {
            console.log(err);
        }

        this.setSettings('is_authorization_check_running', false);
    }

    render() {
         if (this.state.settings.is_authorization_check_running) {
             return (<>...</>);
         }

        return (
            <div className="App">
                {Auth.getInstance().isAuthenticated() ? (
                    <BrowserRouter>
                        <Container style={{maxWidth: "1100px"}}>
                            <OrderPageHeader/>

                                <Switch>
                                    <Route exact path='/' component={OrderPage}></Route>
                                    <Route exact path='/history' component={OrderHistory}></Route>
                                </Switch>

                            <Footer/>
                        </Container>
                    </BrowserRouter>
                ) : (
                    <LoginPage
                        onSuccess={() => setTimeout(() => this.forceUpdate()) }/>
                )}
            </div>
        )
    }

}

export default App;
