import React from 'react';
import {Col, Row, Nav} from "react-bootstrap";
import {Auth} from "../../services/auth/Auth";
import Icon from "@mdi/react";
import { mdiCloseCircle } from '@mdi/js';
import {LinkContainer} from "react-router-bootstrap";



//<LinkContainer to={'/history'}><Nav.Link>history</Nav.Link></LinkContainer>

class OrderPageHeader extends React.Component<any, any> {
    render() {
        return (
            <Row className={"text-left"}>
                <Col xs={"12"} sm={"6"}>
                    <LinkContainer to={'/'}>
                        <img src="/tglogo-300x150.png" alt={""} className={"header-logo"}/>
                    </LinkContainer>
                    <LinkContainer to={'/history'}><Nav.Link>history</Nav.Link></LinkContainer>
                </Col>
                <Col xs={"12"} sm={"6"} className={"text-xs-center text-sm-right pt-sm-5"}>
                    <span className={"logoutButtonWrapper"} onClick={(event: any) => {
                        Auth.getInstance().logout();
                        window.location.reload(false);
                    }}>
                        <Icon
                            size={2}
                            path={mdiCloseCircle}
                        />
                    </span>
                </Col>
            </Row>
        )
    }
}


export default OrderPageHeader;
