import * as logger from 'loglevel';
import {getWebsocketUrlByHostname} from "../../config";

declare var io: any;

export enum WebsocketEvent {
    DriverChanged = 'driver_changed',
    ClientBoarded = 'klient_boarded',
    TaskDelaying = 'task_delaying',
    TaskArchived = 'task_archived',
    TaskTaken = 'task_taken',
    DriverOnline = 'driver_online',
    DriverOffline = 'driver_offline',
    TaskCompleted = 'task_completed',
    DriverArrived = 'in_place',
    TaskCreated = 'task_created',
    SendingTask = 'sending_task',
    Vphone = 'vphone',
    TaskHistoryUpdated = 'task_history_updated',
    RefreshTasks = 'refresh_tasks',
    RefreshTasksV2 = 'refresh_tasks_v2',
    RequestTimeout = 'request_timeout',
    TaskCanceled = 'task_canceled'

}


export enum WebsocketDefaultEvent {
    Connect = 'connect',
    Disconnect = 'disconnect',
    Reconnecting = 'reconnecting',
    Reconnect = 'reconnect',
    ReconnectError = 'reconnect_error',
    ReconnectFailed = 'reconnect_failed',
    Error = 'error'
}


export class Websocket {
    static readonly logPrefix: string = '[WebSocket]';
    private static instance: Websocket;
    private _hostname: string = '';
    private _namespace: any | null= null;
    private _connection: any = null;
    private _credentials: {username: string; password: string} = {
        username: '',
        password: ''
    };
    private _reconnectOnDisconnect: boolean = true;
    private _reconnectOnDisconnectTimeout: number | null = 4000;
    private _defaultEventCallback: {[key: string]: (data: any) => void} = {};


    private constructor() {
    }

    static getInstance(): Websocket {
        if (!Websocket.instance) {
            Websocket.instance = new Websocket();
        }
        return Websocket.instance;
    }

    setHostname(hostname: string): this {
        console.log('setHostname', hostname);
        logger.info(`${Websocket.logPrefix} setHostname(hostname: ${hostname})`);

        this.throwErrorIfConnectionAlive();
        this._hostname = hostname;
        return this;
    }

    setNamespace(namespace: any/*WebsocketNamespace*/): this {
        logger.info(`${Websocket.logPrefix} setNamespace(namespace: ${namespace})`);
        this.throwErrorIfConnectionAlive();
        this._namespace = namespace;
        return this;
    }

    throwErrorIfConnectionAlive(): this {
        logger.info(`${Websocket.logPrefix} throwErrorIfConnectionAlive()`);
        if (this.isConnected()) {
            logger.error(`${Websocket.logPrefix} throwErrorIfConnectionAlive()`);
            throw new Error('Can\'t set credentials while connection alive');
        }
        return this;
    }

    setCredentials(username: string, password: string): this {
        logger.info(`${Websocket.logPrefix} setCredentials(username: ${username}, password: ${password})`);
        this.throwErrorIfConnectionAlive();
        this._credentials = {
            username: encodeURIComponent(username),
            password: encodeURIComponent(password)
        };
        return this;
    }

    private getConnectionQuery(): string {
        logger.info(`${Websocket.logPrefix} getConnectionQuery()`);
        return `username=${this._credentials.username}&password=${this._credentials.password}`;
    }

    private isSecureConnectionAvailable(): boolean {
        logger.info(`${Websocket.logPrefix} isSecureConnectionAvailable()`);
        return this._hostname.indexOf('https://') === 0;
    }

    private reconnectOnDisconnect(is: boolean, timeout: number | null = null) {
        logger.info(`${Websocket.logPrefix} reconnectOnDisconnect()`);
        this._reconnectOnDisconnect = is;
        this._reconnectOnDisconnectTimeout = timeout;
    }

    isConnected(): boolean {
        logger.info(`${Websocket.logPrefix} isConnected()`);
        return this._connection && this._connection.socket.connected;
    }

    reconnect(): this {
        logger.info(`${Websocket.logPrefix} reconnect()`);

        const interval = window.setInterval(() => {
            if (this._connection.socket.connected) {
                clearInterval(interval)
                return;
            }

            this._connection.socket.reconnect();
        }, Number(this._reconnectOnDisconnectTimeout) || 3000);

        return this;
    }

    connect(): this {
        const log: string = `${Websocket.logPrefix} connect()`;

        logger.info(log);

        if (this.isConnected()) {
            logger.error(`${log} alreadty connected`);
            throw new Error('Already connected');
        }
        if (!this._hostname) {
            logger.error(`${log} no hostname provided`);
            throw new Error('No hostname provided');
        }
        if (!this._namespace) {
            logger.error(`${log} no namespace provided`);
            throw new Error('No namespace provided');
        }

        this._connection = io.connect(getWebsocketUrlByHostname(this._hostname) + this._namespace, {
            query: this.getConnectionQuery(),
            secure: this.isSecureConnectionAvailable()
        });
        this._connection.on(WebsocketDefaultEvent.Connect, (data: any) => {
            logger.info(`${log}: connect event`);
            if (this._defaultEventCallback[WebsocketDefaultEvent.Connect]) {
                this._defaultEventCallback[WebsocketDefaultEvent.Connect](data);
            }
        });
        this._connection.on(WebsocketDefaultEvent.Disconnect, (data: any) => {
            logger.info(`${log}: disconnect event | reconnectOnDisconnect: ${this._reconnectOnDisconnect}, data: `, data);
            this._reconnectOnDisconnect && this.reconnect();
            if (this._defaultEventCallback[WebsocketDefaultEvent.Disconnect]) {
                this._defaultEventCallback[WebsocketDefaultEvent.Disconnect](data);
            }
        });
        this._connection.on(WebsocketDefaultEvent.Reconnecting, (data) => {
            logger.info(`${log}: reconnecting event | data:`, data);
        });
        this._connection.on(WebsocketDefaultEvent.Reconnect, (data) => {
            logger.info(`${log}: reconnect event | data:`, data);
        });
        this._connection.on(WebsocketDefaultEvent.ReconnectError, (data) => {
            logger.info(`${log}: reconnect_error event | data:`, data);
        });
        this._connection.on(WebsocketDefaultEvent.ReconnectFailed, (data) => {
            logger.info(`${log}: reconnect_failed event | data:`, data);
        });
        this._connection.on(WebsocketDefaultEvent.Error, (data) => {
            logger.info(`${log}: error event`);
        });

        return this;
    }

    disconnect(): this {
        if (!this.isConnected()) {
            throw new Error('Not connected');
        }

        return this;
    }

    on(event: string, callback: (data: any) => void): this {
        if (event in WebsocketDefaultEvent) {
            if (this._defaultEventCallback[event]) {
                throw new Error(`Event "${event}" already exists`);
            }
            this._defaultEventCallback[event] = callback;
            return this;
        }

        this._connection.on(event, (data) => {
            callback(data);
        });

        return this;
    }
}
