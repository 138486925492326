import {ConfigApiHost, IFirebaseConfig} from "./interfaces";

export const IS_PRODUCTION: boolean = true;

export const CONFIG_API_HOSTS: ConfigApiHost[] = [
    {hostname: 'https://api.taxigo.ee/api', socket: 'https://sio.taxigo.ee:443', title: 'Production', default: true/*Attention*/},
    {hostname: 'https://tr.taxigo.ee/api', socket: 'https://tr.taxigo.ee:443', title: '8080 | Test-release'}
];

export const DEFAULT_CONFIG_ID: number = IS_PRODUCTION ? 0 : 1;
export const REQUEST_CREDENTIALS_POLICY: RequestCredentials = 'include';
export const HTTP_REQUEST_DEFAULT_HEADERS = {'Content-Type':'application/json'};

export const FETCH_DEFAULT_OPTIONS = {
    headers: HTTP_REQUEST_DEFAULT_HEADERS,
    credentials: REQUEST_CREDENTIALS_POLICY
};

export const CLIENT_ACTIVATION_CODE_MAX_LENGTH: number = 4;
export const CONFIG_OLD_ADMIN_PANEL_FALLBACK_URL: string = 'https://intaxigo.eu'; // TODO Replace on iframes, because can't access on http:// api from https:// front


export function getWebsocketUrlByHostname(hostname: string): string | null | undefined {
    const look: ConfigApiHost | undefined = CONFIG_API_HOSTS.find((record: ConfigApiHost) => {
        return record.hostname === hostname;
    });

    return look ? look.socket : null;
}

export enum WebsocketNamespace {
    Client = '/klient'
}

export enum AclUserRole {
    Client = 'client'
}

export enum DRIVER_REQUEST_METHOD {
    DIRECT = 0,
    AUTOSEARCH = 1
}

export enum PaymentMethod {
    CASH = 0,
    CARD_TERMINAL = 1,
    EVERYPAY = 2,
    CONTRACT = 3,
    MOBILE = 4,
    BONUS = 5
}

export const DEFAULT_VEHICLE_IMG_SMALL: string = 'https://storage.googleapis.com/storage-taxigo/cars_images_small/veh_default.jpg';

export const GOOGLE_PLACES_LOOK_SUCH_COUNTRIES: string[] = ['ee', 'mt', 'ly'];

export const DELIVERY_CATEGORY_ID: number = 7; // REPLACE ON 7, BEFORE PROD
export const UNKNOWN_PAYMENT_METHOD_ID: number = -1;
export const SESSION_USER_SELF_IDENTIFICATOR: string = 'me';


export enum OrderState {
    DRIVER_ARRIVED = 12,
    TIMEOUT = 11,
    CANCELED_BY_ADMIN = 10,
    CANCELED_BY_DISPATCHER = 9,
    CANCELED_BY_CLIENT = 8,
    REJECTED_BY_DRIVER = 7,
    CANCELED_BY_DRIVER = 6,
    SCHEDULED = 5,
    DONE = 4,
    ONBOARD = 3,
    RUNNING = 2,
    TAKEN_BY_DRIVER = 1,
    ASKING = 0
};

export const FIREBASE_CONFIG: IFirebaseConfig = {
    apiKey: "AIzaSyAOY534quTBTJd2-x9oktBAyYH5w-y7-H4",
    authDomain: "lateral-origin-90511.firebaseapp.com",
    projectId: "lateral-origin-90511",
    storageBucket: "lateral-origin-90511.appspot.com",
    messagingSenderId: "419631116662",
    appId: "1:419631116662:web:45818b7830da0cd5540a57",
    measurementId: "G-XX7TFWNLV7"
}

export const RECAPTCHA_ENTERPRISE_KEY = "6LfkFPYfAAAAAPvpGU4wcbEfv9ofeveVfR4dhwFH"


