import React from 'react';
import {Provider} from "mobx-react";
import {OrderPageState} from "../../observers/OrderPage.observer";
import {OrderList} from "./OrderList";
import {OrderForm} from "./OrderForm";

import './OrderPage.css';
import {IOrderPageProps, IOrderPageSettings, IOrderPageState} from "./interfaces";

class OrderPage extends React.Component<IOrderPageProps, IOrderPageState> {
    store = new OrderPageState();

    state: IOrderPageState = {
        settings: {
            is_loading: true
        }
    };

    async componentDidMount(): Promise<void> {
        await Promise.all([
            this.store.getPaymentMethods(),
            this.store.getOrderFormDefaults(),
            this.store.refreshOrdersList(),
        ]);
        //await this.store.initializeWebsocket();
        this.setSettings('is_loading', false);
    }

    setSettings(key: keyof IOrderPageSettings, value: any) {
        this.setState({settings: Object.assign(this.state.settings, {[key]: value})});
    }

    render() {
        if (this.state.settings.is_loading) {
            return (<></>);
        }

        return (

            <Provider store={this.store}>
                <OrderForm store={this.store}/>
                <br/>
                <OrderList/>
            </Provider>
        )
    }
}


export default OrderPage;
