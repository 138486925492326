import {Websocket} from "../Websocket/Websocket";
import Api from "../api/Api";
import {IApiCredentials} from "../../interfaces";
import {AclUserRole, WebsocketNamespace} from "../../config";

Websocket.getInstance();

export class Auth {
    static readonly LOCAL_STORAGE_API_CREDENTIALS_KEY: string = 'api_credentials';
    static readonly LOCAL_STORAGE_API_HOSTNAME_KEY: string = 'api_hostname';
    static readonly LOCAL_STORAGE_USER_SESSION_KEY: string = 'usess';
    private static instance: Auth;
    private _isAuthenticated: boolean = false;
    private _hostname: string = '';

    private constructor() {
    }

    static getInstance(): Auth {
        if (!Auth.instance) {
            Auth.instance = new Auth();
        }

        return Auth.instance;
    }

    static isSuccessResponse(status: number) {
        return status >= 200 && status <= 304
    }

    getHostname(): string {
        return localStorage.getItem(Auth.LOCAL_STORAGE_API_HOSTNAME_KEY) || this._hostname;
    }

    setIsAuthenticated(status: boolean): this {
        this._isAuthenticated = status;
        return this;
    }

    setHostname(hostname: string): this {
        this._hostname = hostname;
        localStorage.setItem(Auth.LOCAL_STORAGE_API_HOSTNAME_KEY, this._hostname);

        return this;
    }

    isAuthenticated(): boolean {
        return this._isAuthenticated;
    }

    async checkIsAuthorized(userRole: AclUserRole | null = null, activationCode: string): Promise<{id: number}> {
        try {
            const response: {id: number} = await Api.getInstance().isUserAuthorized(userRole, activationCode);
            this.setIsAuthenticated(true);
            return response;
        }
        catch (err) {
            this.setIsAuthenticated(false);

            throw err;
        }
    }

    getCredentials(): IApiCredentials  {
        const value: string | null = localStorage.getItem(Auth.LOCAL_STORAGE_API_CREDENTIALS_KEY);
        return value ? JSON.parse(value) : null;
    }

    setCredentials(username: string, password: string): void {
        localStorage.setItem(Auth.LOCAL_STORAGE_API_CREDENTIALS_KEY, JSON.stringify({
            username: username,
            password: password
        }));
        return;
    }

    getWebsocketNamespace() {
        return WebsocketNamespace.Client;
    }

    async logout(): Promise<void> {
        await Api.getInstance().clientLogout()
        localStorage.clear();
        return;
    }
}


