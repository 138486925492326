import {inject, observer} from "mobx-react";
import Api from "../../services/api/Api";
import {Button, Card, Col, Row, Badge} from "react-bootstrap";
import {IClientOrder, IGetCabTaskExtra} from "../../interfaces";
import Moment from "react-moment";
import React from "react";
import {OrderPageState} from "../../observers/OrderPage.observer";
import {DEFAULT_VEHICLE_IMG_SMALL, OrderState} from "../../config";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {mdiAlphaACircle, mdiAlphaBCircle} from '@mdi/js';
import Icon from "@mdi/react";

import './OrderList.css';
import {toast, ToastContainer} from "react-toastify";


export const OrderList = inject("store") (
    observer ((props: { store?: OrderPageState }) => {
        function formatAddress(address: {
            id: number;
            full: string;
            city: string;
            street: string
            house_number: string;
        }): string {
            return address.city && address.street && address.house_number ? `${address.city}, ${address.street} ${address.house_number}` : address.full;
        }

        async function cancelOrder(orderId: number) {
            if (window.confirm('Are you sure?')) {
                await Api.getInstance().cancelOrderAsClient(orderId);
                props.store!.refreshOrdersList();
            }
        }

        async function searchAgain(order: IClientOrder): Promise<void> {
            const extra: IGetCabTaskExtra = await Api.getInstance().getCabTaskExtra(order.task.id);

            const pickup = order.waypoints[0];
            const destination = order.waypoints[1];

            if (!pickup) {
                toast(`No pickup address`, {
                    type: 'warning',
                    autoClose: 3000
                });
                return;
            }
            if (!destination) {
                toast(`No destination address`, {
                    type: 'warning',
                    autoClose: 3000
                });
                // toast
                return;
            }

            await props.store!.sendOrder({
                pickup_address: pickup.address.full,
                pickup_address_lat: pickup.location.lat,
                pickup_address_lng: pickup.location.lng,
                pickup_contact_phone: extra.data.client_username,
                pickup_contact_person: extra.data.client_name,
                pickup_notes_for_courier: extra.pickup_notes_for_courier,
                pickup_describe_package: extra.package_description,
                finish_address: destination.address.full,
                finish_address_lat: destination.location.lat,
                finish_address_lng: destination.location.lng,
                finish_contact_phone: extra.finish_contact_phone,
                finish_contact_person: extra.finish_contact_person,
                finish_notes_for_courier: extra.finish_notes_for_courier,
                payment_method_id: extra.data.payment_method_id,
                payment_method_client_company_id: extra.data.client_company_id,
                fixprice_hash: null
            });
            await props.store!.refreshOrdersList();
        }

        function getStateNameById(stateId: number) {
            let template: any = (<span>-</span>);

            switch (stateId) {
                case OrderState.ONBOARD:
                    template = (<span className={"mybadge delivering"}>DELIVERING</span>)
                break;
                case OrderState.TAKEN_BY_DRIVER:
                    template =  (<span className={"mybadge driving-to-pickup"}>DRIVING TO PICKUP</span>)
                break;
                case OrderState.ASKING:
                    template = (<span className={"mybadge requesting"}>REQUESTING...</span>)
                break;
                case OrderState.DRIVER_ARRIVED:
                    template = (<span className={"mybadge in-pickup"}>IN PICKUP</span>)
                break;
                case OrderState.CANCELED_BY_ADMIN:
                    template = (<span className={"mybadge cancelled"}>Cancelled by admin</span>)
                break;
                case OrderState.CANCELED_BY_CLIENT:
                    template = (<span className={"mybadge cancelled"}>Cancelled by client</span>)
                break;
                case OrderState.CANCELED_BY_DRIVER:
                    template = (<span className={"mybadge cancelled"}>Cancelled by driver</span>)
                break;
                case OrderState.CANCELED_BY_DISPATCHER:
                    template = (<span className={"mybadge cancelled"}>Cancelled by dispatcher</span>)
                break;
                default:
                    return '-'
            }

            return template;
        }

        // order-list-wrapper

        if (!props.store!.orders.length) {
            return (<></>)
        }




        return (
            <div className={"order-list-wrapper d-flex flex-row justify-content-center p-1"} style={{background: 'white', flexWrap: 'wrap'}}>
                    {props.store!.orders.map((order: IClientOrder, index) => {
                        const isOrderCanceled: boolean = [
                            OrderState.CANCELED_BY_CLIENT,
                            OrderState.CANCELED_BY_DISPATCHER,
                            OrderState.CANCELED_BY_DRIVER,
                            OrderState.CANCELED_BY_ADMIN
                        ].indexOf(Number(order.task.state_id)) >= 0;

                        return (
                            <Card className={'m-2 text-left order-card' + (isOrderCanceled ? ' bit-faded' : '')} key={index}>
                                <Card.Img variant="top" src={order.vehicle && order.vehicle.small_photo_url ? order.vehicle.small_photo_url : DEFAULT_VEHICLE_IMG_SMALL} />
                                <Card.Body>
                                    {getStateNameById(order.task.state_id)}
                                    <Badge variant={"primary"} className={"w-100"}>{ order.task.id } | <Moment format="DD.MM.YYYY HH:mm">{ order.task.created_at }</Moment></Badge>
                                    {isOrderCanceled && (<Badge variant={"primary"} className={"w-100"}>&nbsp;</Badge>)}
                                    {!isOrderCanceled && order.driver && (
                                        <CopyToClipboard
                                            text={order.driver.phone}
                                            onCopy={() => {
                                                toast('Phone copied to clipboard', {
                                                type: 'default',
                                                autoClose: 2000
                                            });
                                        }}>
                                            <Badge variant={"primary"} className={"w-100 driver-info-badge"}>{order.driver.name} | {order.driver.phone}</Badge>
                                        </CopyToClipboard>
                                    )}
                                    <br/><br/>
                                    <Card.Title>
                                        <Icon size={1} path={mdiAlphaACircle} className={"mr-2"}/>
                                        <span>{ order.waypoints[0] ? formatAddress(order.waypoints[0].address) : 'N/A'}</span>
                                    </Card.Title>
                                    <Card.Title>
                                        <Icon size={1} path={mdiAlphaBCircle} className={"mr-2"}/>
                                        <span>{ order.waypoints[1] ? formatAddress(order.waypoints[1].address) : 'N/A'}</span>
                                    </Card.Title>
                                    <Card.Text>
                                        <span>Comment:</span>
                                        <br/>
                                        <span>{order.task.comment}</span>
                                        <br/>
                                        <Badge variant={"primary"}>
                                            { order.tariff && order.tariff.fixed_price ? `${order.tariff.fixed_price}${order.tariff.currency.sign}` : 'N/A'}
                                        </Badge>
                                    </Card.Text>
                                    { !isOrderCanceled && (
                                        <Row>
                                            <Col>
                                            { order.task.app_web_ui_url && (
                                                <CopyToClipboard
                                                    text={order.task.app_web_ui_url}
                                                    onCopy={() => {
                                                        toast('Copied to clipboard', {
                                                            type: 'default',
                                                            autoClose: 2000
                                                        });
                                                    }}>
                                                    <Button variant="primary" className={"w-100 mt-1"}>Web-link</Button>
                                                </CopyToClipboard>
                                            )}
                                            </Col>
                                            <Col>
                                                <Button
                                                    variant="danger"
                                                    className={"w-100 mt-1"}
                                                    onClick={(event: any) => cancelOrder(order.task.id)}
                                                >Cancel</Button>
                                            </Col>
                                        </Row>
                                    )}
                                    { isOrderCanceled && (
                                        <Button
                                            variant="danger"
                                            className={"w-100 mt-1"}
                                            onClick={(event: any) => searchAgain(order)}
                                        >SEARCH AGAIN</Button>
                                    )}


                                </Card.Body>
                            </Card>


                        );
                    })}

                <ToastContainer/>
            </div>
        )
    })
);
