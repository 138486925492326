import React from 'react';
import Api from "../../services/api/Api";
import {IOrderHistoryElement, IOrderHistoryProps, IOrderHistorySettings, IOrderHistoryState} from "../../interfaces";
import {Badge, Card, Col, Row} from "react-bootstrap";
import {mdiAlphaACircle, mdiAlphaBCircle, mdiCardAccountDetailsOutline} from "@mdi/js";
import Icon from "@mdi/react";
import Moment from "react-moment";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {toast, ToastContainer} from "react-toastify";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import './OrderHistory.css';
import moment from 'moment';

class OrderHistory extends React.Component<IOrderHistoryProps, IOrderHistoryState> {
    state: IOrderHistoryState = {
        orders: [],
        settings: {
            is_loading: true
        },
        datepicker: {
            start: new Date(),
            end: new Date()
        }
    };

    async componentDidMount(): Promise<void> {
        await this.fetchOrders();
    }

    handleChange(date, input: 'start' | 'end', callback?: () => void) {
        this.setState({
            datepicker: Object.assign(this.state.datepicker, {
                [input]: date
            })
        }, callback);
    }

    async fetchOrders() {
        const dateStart: string = moment(this.state.datepicker.start).format('YYYY-MM-DD');
        const dateEnd: string = moment(this.state.datepicker.end).format('YYYY-MM-DD');

        this.setSettings('is_loading', true);
        // TODO: Maybe pass it into URL?
        const orders: IOrderHistoryElement[] = await Api.getInstance().getClientTrips(dateStart, dateEnd);

        this.setState({orders: orders}, () => this.setSettings('is_loading', false));
    }


    setSettings(key: keyof IOrderHistorySettings, value: any) {
        this.setState({settings: Object.assign(this.state.settings, {[key]: value})});
    }

    render() {
        return (
            <>
                <ToastContainer/>
                <Card>
                    <Card.Body className={"text-left bg-primary"}>
                        <Row className={"mb-2"}>
                            <Col>
                                <div style={{display: 'inline-block', color: 'white'}}>
                                    <span><b>Choose date</b></span>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div style={{display: 'inline-block'}}>
                                    <DatePicker
                                        className={"order-history-datepicker form-control"}
                                        selected={this.state.datepicker.start}
                                        onChange={(date) => this.handleChange(date, 'start', () => this.fetchOrders())}
                                        disabled={this.state.settings.is_loading}
                                        maxDate={this.state.datepicker.end}
                                        dateFormat="dd.MM.yyyy"
                                    />
                                </div>
                                &nbsp;&nbsp;
                                <div style={{display: 'inline-block'}}>
                                    <DatePicker
                                        className={"order-history-datepicker form-control"}
                                        selected={this.state.datepicker.end}
                                        onChange={(date) => this.handleChange(date, 'end', () => this.fetchOrders())}
                                        disabled={this.state.settings.is_loading}
                                        minDate={this.state.datepicker.start}
                                        dateFormat="dd.MM.yyyy"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <br/>
                <div className={"d-flex flex-row p-2 justify-content-center order-history"}>
                    {this.state.orders.map((order: IOrderHistoryElement, index: number) => {
                        return (<Card className={"m-2 p-2 text-left order-history-card"} key={index}>
                            <Row noGutters={true}>
                                <Col className={"text-left"}>
                                    <Badge variant={"info"} style={{width: '70px'}}>{ order.task.id}</Badge>
                                </Col>
                                <Col className={"text-left"}>
                                    <Badge variant={"info"} style={{width: '70px'}}>{ order.task.price} {order.currency.sign}</Badge>
                                </Col>
                                <Col className={"text-right"}>
                                    <Badge variant={"info"} style={{width: '120px'}}>
                                        <Moment format="DD.MM.YYYY HH:mm">{ order.task.start_time }</Moment>
                                    </Badge>
                                </Col>
                            </Row>
                            <br/>
                            <Card.Title style={{fontSize: '10pt'}}>
                                <Icon size={1} path={mdiAlphaACircle} className={"mr-2"}/>
                                <Badge variant={"secondary"} style={{textAlign: 'center', width: '40px'}}>
                                    <Moment format="HH:mm">{ order.task.start_time }</Moment>
                                </Badge>&nbsp;&nbsp;
                                <span>{ order.task.start_address}</span>
                            </Card.Title>
                            <Card.Title style={{fontSize: '10pt'}}>
                                <Icon size={1} path={mdiAlphaBCircle} className={"mr-2"}/>
                                <Badge variant={"secondary"} style={{textAlign: 'center', width: '40px'}}>
                                    <Moment format="HH:mm">{ order.task.finish_time }</Moment>
                                </Badge>&nbsp;&nbsp;
                                <span>{ order.task.destination_address}</span>
                            </Card.Title>

                            <Card.Title style={{fontSize: '10pt'}}>
                                <Icon size={1} path={mdiCardAccountDetailsOutline} className={"mr-2"}/>
                                <Badge variant={"secondary"} style={{textAlign: 'center', width: '40px'}}>
                                    {order.driver.license_nr}
                                </Badge>&nbsp;&nbsp;
                                <CopyToClipboard
                                    text={order.driver.phone}
                                    onCopy={() => {toast('Phone copied to clipboard', {type: 'default', autoClose: 2000});}}
                                >
                                    <span>{order.driver.name}</span>
                                </CopyToClipboard>
                            </Card.Title>
                        </Card>)
                    })}
                </div>
            </>
        )
    }
}

export default OrderHistory;
